<template>
  <b-card class="a-card-menu pt-1" :class="hasBreadcumbs ? 'mt-5' : ''">
    <h3 class="w-color-black fw-bold mb-4">{{ welcomeText }}</h3>
    <b-list-group flush>
      <template v-for="(subpage, key, i) in profileLinks">
        <b-list-group-item
          v-if="subpage.url"
          :key="key"
          :to="subpage.url"
          class="d-flex"
          :class="i === Object.keys(profileLinks).length - 1 ? 'pt-3' : 'py-3'"
        >
          <component
            :is="subpage.icon"
            class="icon"
            :class="`w-color-${subpage.iconColor}`"
          ></component>
          {{ subpage.title }}
        </b-list-group-item>
      </template>
      <div class="contact-info">
        <h3>{{ $t("do-you-need-help") }}</h3>
        <span>{{ $t("contact-us") }}</span>
        <div class="contact-list">
          <span v-if="isAdmin"
            >{{ $t("phone") }}:
            <a class="w-color-primary" href="tel:+421947941519"
              >+421 947 941 519</a
            ></span
          >
          <span
            >{{ $t("email") }}:
            <a class="w-color-primary" href="mailto:skoly@zmudri.sk"
              >skoly@zmudri.sk</a
            ></span
          >
        </div>
        <hr />
      </div>

      <b-list-group-item class="logout" @click.prevent="logout">
        <svg-logout-icon class="icon" />{{ $t("Odhlásiť") }}
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "svg-logout-icon": () => import("/assets/icons/logout.svg?inline"),
  },
  data() {
    return {
      hasBreadcumbs: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
    userType() {
      return this.user && this.user.type;
    },
    welcomeText() {
      return this.userType === "teacher"
        ? this.$t("Vitajte v profile")
        : this.$t("Vitaj v profile");
    },
    profileLinks() {
      return {
        teacher: {
          news: {
            title: "Novinky",
            url: "/news",
            icon: () => import("/assets/icons/lightning.svg?inline"),
            iconColor: "pink-news",
          },
          userManual: {
            title: "Pomocník",
            url: "/pomocnik",
            icon: () => import("/assets/icons/userManual.svg?inline"),
            iconColor: "purple-user-manual",
          },
          classes: {
            title: "Triedy",
            url: "/triedy",
            icon: () => import("/assets/icons/groups.svg?inline"),
            iconColor: "orange-classes",
          },
          diploms: {
            title: "Moje diplomy",
            url: "/diplomy",
            icon: () => import("/assets/icons/ribbon.svg?inline"),
            iconColor: "orange-diploms",
          },
          profile: {
            title: "Moje údaje",
            url: "/profil",
            icon: () => import("/assets/icons/user.svg?inline"),
            iconColor: "primary",
          },
          favoriteCourses: {
            title: "Moje kurzy a série",
            url: "/oblubene-kurzy",
            icon: () => import("/assets/icons/heart.svg?inline"),
            iconColor: "secondary",
          },
        },
        student: {
          classes: {
            title: "Triedy",
            url: "/triedy",
            icon: () => import("/assets/icons/groups.svg?inline"),
            iconColor: "orange-classes",
          },
          diploms: {
            title: "Diplomy",
            url: "/diplomy",
            icon: () => import("/assets/icons/ribbon.svg?inline"),
            iconColor: "orange-diploms",
          },
          profile: {
            title: "Moje údaje",
            url: "/profil",
            icon: () => import("/assets/icons/user.svg?inline"),
            iconColor: "primary",
          },
          favoriteCourses: {
            title: "Moje kurzy a série",
            url: "/oblubene-kurzy",
            icon: () => import("/assets/icons/heart.svg?inline"),
            iconColor: "secondary",
          },
        },
      }[this.userType];
    },
  },
  methods: {
    async logout() {
      this.$eventLogger.userLog("user.logout", { timestamp: new Date() });
      await this.$store.dispatch("auth/logout");
      localStorage.removeItem("LS_LAST_ROUTE");
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler: function () {
        //for now only triedy route has breacrumbs
        this.hasBreadcumbs = this.$route.path.includes("/triedy");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.a-card-menu {
  .router-link-active:not(.logout) {
    font-weight: bold;
  }

  a:hover {
    color: var(--ion-color-primary);
    text-decoration: none;
    cursor: pointer;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 1.25rem;
    overflow: visible;
  }

  .contact-info {
    margin-top: 1.5rem;

    h3 {
      margin-bottom: 0.75rem;
    }

    .contact-list {
      margin: 1.5rem 0;

      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
</style>
